import { FC, useEffect, useMemo, useState } from "react";
import { VacancyListViewModel } from "./VacancyListViewModel.csharp";
import * as React from "react";
import { VacancyItemProps } from "./VacancyItemProps.csharp";
import { ComponentProps } from "../../Partials/ComponentProps.csharp";
import { VacancyListFilters } from "./VacancyListFilters";
import { SelectOption } from "../../react-components/Inputs/SelectOption";
import { VacancyFilterItemProps } from "./VacancyFilterItemProps.csharp";
import { LinkArrowBlockList } from "../../react-components/LinkArrowBlockList/LinkArrowBlockList";

export interface VacancyListState {
  selectedPositionTypeId: string;
  selectedDepartmentId: string;
  selectedRegionId: string;
  searchPhrase: string;
}

const QueryKeys = {
  SearchPhrase: "searchPhrase",
  Position: "position",
  Department: "department",
  Region: "region",
};

const setQueryValue = (key: string, value: string | null) => {
  const url = new URL(location.href);
  if (!value) {
    url.searchParams.delete(key);
  } else {
    url.searchParams.set(key, value);
  }
  history.replaceState(null, "", url);
};

const getQueryValue = (key: string): string | null => {
  const url = new URL(location.href);
  return url.searchParams.get(key);
};

export const VacancyList: FC<ComponentProps<VacancyListViewModel>> = ({ model }) => {
  const [vacancyFilters, setVacancyFilters] = useState<VacancyListState>({
    selectedPositionTypeId: "",
    selectedDepartmentId: "",
    selectedRegionId: "",
    searchPhrase: "",
  });

  useEffect(() => {
    if (window === undefined) {
      return;
    }

    setVacancyFilters({
      selectedPositionTypeId: getQueryValue(QueryKeys.Position) ?? "",
      selectedDepartmentId: getQueryValue(QueryKeys.Department) ?? "",
      selectedRegionId: getQueryValue(QueryKeys.Region) ?? "",
      searchPhrase: getQueryValue(QueryKeys.SearchPhrase) ?? "",
    });
  }, []);

  const matchesSearchPhrase = (searchPhrase: string, vacancyItem: VacancyItemProps): boolean => {
    if (searchPhrase.length === 0) {
      return true;
    }

    const searchContent = vacancyItem.searchContent.toLowerCase();
    const words = searchPhrase.match(/\S+/g) || []; //split into words by whitespaces

    const notFoundWords = words.filter((word) => searchContent.indexOf(word) < 0);
    return notFoundWords.length === 0;
  };

  const matchesSelectedFilter = (vacancyItem: VacancyItemProps): boolean => {
    if (
      vacancyFilters.selectedDepartmentId.length > 0 &&
      !vacancyFilters.selectedDepartmentId.includes(vacancyItem.departmentId)
    ) {
      return false;
    }

    if (
      vacancyFilters.selectedRegionId &&
      vacancyItem.regionIds.filter((x) => x === vacancyFilters.selectedRegionId).length === 0
    ) {
      return false;
    }

    if (
      vacancyFilters.selectedPositionTypeId &&
      vacancyItem.positionTypeIds.filter((x) => x === vacancyFilters.selectedPositionTypeId)
        .length === 0
    ) {
      return false;
    }

    const searchPhrase = vacancyFilters.searchPhrase.trim();
    if (searchPhrase.length > 0 && !matchesSearchPhrase(searchPhrase.toLowerCase(), vacancyItem)) {
      return false;
    }

    return true;
  };

  const filteredVacancies = useMemo(
    () => model.vacancies.filter((x) => matchesSelectedFilter(x)),
    [vacancyFilters],
  );

  const onSelectedDepartment = (option: SelectOption) => {
    setVacancyFilters((prev) => ({
      ...prev,
      selectedDepartmentId: option.value,
    }));
    setQueryValue(QueryKeys.Department, option.value);
  };

  const onSelectedRegion = (option: SelectOption) => {
    setVacancyFilters((prev) => ({
      ...prev,
      selectedRegionId: option.value,
    }));
    setQueryValue(QueryKeys.Region, option.value);
  };

  const onSelectedPositionType = (option: SelectOption) => {
    setVacancyFilters((prev) => ({
      ...prev,
      selectedPositionTypeId: option.value,
    }));
    setQueryValue(QueryKeys.Position, option.value);
  };

  const onSearchPhraseChange = (value: string) => {
    setVacancyFilters((prev) => ({
      ...prev,
      searchPhrase: value,
    }));
    setQueryValue(QueryKeys.SearchPhrase, value);
  };

  const resetFilters = () => {
    setQueryValue(QueryKeys.SearchPhrase, null);
    setQueryValue(QueryKeys.Region, null);
    setQueryValue(QueryKeys.Position, null);
    setQueryValue(QueryKeys.Department, null);

    setVacancyFilters({
      selectedPositionTypeId: "",
      selectedDepartmentId: "",
      selectedRegionId: "",
      searchPhrase: "",
    });
  };

  const generateOptions = (items: VacancyFilterItemProps[]) => {
    return items.map((item) => ({ label: item.text, value: item.value ?? item.values.join(",") }));
  };

  return (
    <div className="ItemList">
      <VacancyListFilters
        positionOptions={generateOptions(model.positionTypeFilterItems)}
        departmentOptions={generateOptions(model.departmentFilterItems)}
        regionOptions={generateOptions(model.regionFilterItems)}
        onSearchPhraseChange={onSearchPhraseChange}
        onSelectedRegion={onSelectedRegion}
        onSelectedPositionType={onSelectedPositionType}
        onSelectedDepartment={onSelectedDepartment}
        vacancyFilters={vacancyFilters}
        vacanciesCount={filteredVacancies.length}
        resetFilters={resetFilters}
      />
      <div className="ItemList__items">
        <LinkArrowBlockList
          items={filteredVacancies.map((vacancy) => ({
            text: vacancy.title,
            href: vacancy.detailsUrl,
            location: vacancy.location,
          }))}
        />
      </div>
    </div>
  );
};