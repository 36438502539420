import React, { useMemo, useState } from "react";
import { ResponsivePicture } from "../../react-components/responsivePicture/responsivePicture";
import { ImageAndFactViewModel } from "./ImageAndFactViewModel.csharp";
import { ResponsivePictureProps } from "../../Images/ResponsivePictureProps.csharp";
import { uniqueId } from "lodash";

export type ImageAndFactWithIdentity<TImageAndFact extends ImageAndFactViewModel> =
  TImageAndFact & { id: string };

export function useFacts<TImageAndFact extends ImageAndFactViewModel>(
  facts: TImageAndFact[],
  mainImage: ResponsivePictureProps,
) {
  const [selectedFact, setSelectedFact] = useState<ImageAndFactWithIdentity<TImageAndFact> | null>(
    null,
  );

  const factsList: ImageAndFactWithIdentity<TImageAndFact>[] = useMemo(
    () =>
      facts.map((fact) => ({
        ...fact,
        id: uniqueId("ImageWithFactsViewerSingleFact"),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const getActiveImage = () => {
    if (selectedFact === null || selectedFact.image === null) {
      if (mainImage !== null) {
        return <ResponsivePicture model={mainImage.model} profile={mainImage.profile} />;
      } else {
        return null;
      }
    }

    return (
      <ResponsivePicture model={selectedFact.image.model} profile={selectedFact.image.profile} />
    );
  };

  return [factsList, selectedFact, setSelectedFact, getActiveImage] as const;
}
