import React, { FC, useState } from "react";
import { FormModal } from "../../../react-components/Modal/FormModal/FormModal";

export interface CallToActionModalButtonProps {
  id: string;
  className: string;
  header: string;
  text: string;
}

export const CallToActionModalButton: FC<CallToActionModalButtonProps> = ({
  id,
  className,
  header,
  text,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  return (
    <>
      <button
        className={className}
        onClick={() => {
          setIsOpen(true);
        }}
        disabled={isDisabled}
      >
        {text}
      </button>
      <FormModal
        id={id}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        header={header}
        onModalContentExtracted={() => setIsDisabled(false)}
      />
    </>
  );
};
