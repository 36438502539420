import { FC, useMemo, useRef } from "react";
import * as React from "react";
import { useIsBelowScreenSize } from "../../react-components/useIsBelowScreenSize";
import { ButtonGhost } from "../../react-components/Buttons/ButtonGhost";
import { SelectOption } from "../../react-components/Inputs/SelectOption";
import { Reset } from "../../react-components/Discovery/Icons/Reset";
import { OfficeLocationListState } from "./OfficeLocationsContainer";
import { OfficeListFiltersMobile } from "./OfficeListFiltersMobile";
import { FilterInput } from "../../react-components/Inputs/FilterInput/FilterInput";
import { FilterSelect } from "../../react-components/Inputs/FilterSelect/FilterSelect";

interface OfficeLocationsListFiltersProps {
  countryOptions: SelectOption[];
  contactTypesOptions: SelectOption[];
  businessAreaOptions: SelectOption[];
  productCategoryOptions: SelectOption[];
  onSearchPhraseChange: (value: string) => void;
  onSelectedCountry: (value: SelectOption) => void;
  onSelectedContactType: (value: SelectOption) => void;
  onSelectedBusinessArea: (value: SelectOption) => void;
  onSelectedProductCategory: (value: SelectOption) => void;
  officeLocationListFilters: OfficeLocationListState;
  resetFilters: () => void;
}

export const OfficeLocationsFilters: FC<OfficeLocationsListFiltersProps> = ({
  countryOptions,
  contactTypesOptions,
  businessAreaOptions,
  productCategoryOptions,
  onSearchPhraseChange,
  onSelectedCountry,
  onSelectedContactType,
  onSelectedBusinessArea,
  onSelectedProductCategory,
  officeLocationListFilters,
  resetFilters,
                                                                }) => {
  const isMobile = useIsBelowScreenSize("tablet");
  const topDivRef = useRef<HTMLDivElement>(null);

  const scrollToTopDiv = () => {
    if (topDivRef.current) {
      topDivRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const currentCountry = useMemo(() => {
    if (officeLocationListFilters.selectedCountry.length === 0) {
      return undefined;
    }

    return countryOptions.find((option) => option.value === officeLocationListFilters.selectedCountry);
  }, [officeLocationListFilters.selectedCountry]);

  const currentProductCategory = useMemo(() => {
    if (officeLocationListFilters.selectedProductCategory === undefined) {
      return undefined;
    }

    return productCategoryOptions.find((option) => option.value === officeLocationListFilters.selectedProductCategory?.toString());
  }, [officeLocationListFilters.selectedProductCategory]);

  const currentBusinessArea = useMemo(() => {
    if (officeLocationListFilters.selectedBusinessArea.length === 0) {
      return undefined;
    }

    return businessAreaOptions.find((option) => option.value === officeLocationListFilters.selectedBusinessArea);
  }, [officeLocationListFilters.selectedBusinessArea]);

  const currentContactType = useMemo(() => {
    if (!officeLocationListFilters.selectedContactType) {
      return undefined;
    }

    return contactTypesOptions.find((option) => option.value === officeLocationListFilters.selectedContactType.toString());
  }, [officeLocationListFilters.selectedContactType]);


  const showResetFiltersButton =
    currentCountry || currentContactType || currentBusinessArea || currentProductCategory || officeLocationListFilters.searchPhrase?.length;

  const withScrollToTop = <T, >(handler: (value: T) => void): ((value: T) => void) => {
    return (value: T) => {
      scrollToTopDiv();
      handler(value);
    };
  };

  return (
    <>
      <div ref={topDivRef} className="TopDivAboveFilters" />
      <div className="Filters Filters--sand">
        {!isMobile ? (
          <ul className="FiltersList">
            <li className="FiltersList__item">
              <FilterInput
                label="Search"
                value={officeLocationListFilters.searchPhrase}
                onValueChanged={withScrollToTop(onSearchPhraseChange)}
              />
            </li>
            <li className="FiltersList__item">
              <FilterSelect
                label={currentContactType ? currentContactType.label : "All Contacts"}
                name="contactType"
                options={contactTypesOptions}
                onChange={withScrollToTop(onSelectedContactType)}
                placeholder="All contacts"
                placeholderAsFirstOption
                currentValue={currentContactType}
                heading="Contact"
              />
            </li>
            <li className="FiltersList__item">
              <FilterSelect
                label={currentCountry ? currentCountry.label : "All Countries"}
                name="location"
                options={countryOptions}
                onChange={withScrollToTop(onSelectedCountry)}
                placeholder="All countries"
                placeholderAsFirstOption
                currentValue={currentCountry}
                heading="Location"
              />
            </li>
            {productCategoryOptions.length > 0 && (
              <li className="FiltersList__item">
                <FilterSelect
                  label={currentProductCategory ? currentProductCategory.label : "All products"}
                  name="location"
                  options={productCategoryOptions}
                  onChange={withScrollToTop(onSelectedProductCategory)}
                  placeholder="All products"
                  placeholderAsFirstOption
                  currentValue={currentProductCategory}
                  heading="Product area"
                />
              </li>
            )}
            {businessAreaOptions.length > 0 && (
              <li className="FiltersList__item">
                <FilterSelect
                  label={currentBusinessArea ? currentBusinessArea.label : "All Business Areas"}
                  name="business-area"
                  options={businessAreaOptions}
                  onChange={withScrollToTop(onSelectedBusinessArea)}
                  placeholder="All Business Areas"
                  placeholderAsFirstOption
                  currentValue={currentBusinessArea}
                  heading="Business Area"
                />
              </li>
            )}
            {!!showResetFiltersButton && (
              <li className="FiltersList__item">
                <ButtonGhost
                  iconPosition="right"
                  icon={Reset}
                  mode="light"
                  color="blue"
                  onClick={withScrollToTop(resetFilters)}
                  className="FiltersList__resetBtn"
                >
                  Reset filters
                </ButtonGhost>
              </li>
            )}
          </ul>
        ) : (
          <OfficeListFiltersMobile
            countryOptions={countryOptions}
            contactTypesOptions={contactTypesOptions}
            businessAreaOptions={businessAreaOptions}
            productCategoryOptions={productCategoryOptions}
            onSearchPhraseChange={onSearchPhraseChange}
            onSelectedCountry={onSelectedCountry}
            onSelectedContactType={onSelectedContactType}
            onSelectedBusinessArea={onSelectedBusinessArea}
            onSelectedProductCategory={onSelectedProductCategory}
            searchPhrase={officeLocationListFilters.searchPhrase}
            country={currentCountry}
            productCategory={currentProductCategory}
            contactType={currentContactType}
            businessArea={currentBusinessArea}
            showResetFiltersButton={!!showResetFiltersButton}
            resetFilters={resetFilters}
          />
        )}
      </div>
    </>
  );
};