import React, { FC, useCallback, useEffect, useRef } from "react";
import { Modal } from "../../../react-components/Modal/Modal";

export interface FormModalProps {
  id: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  header: string;
  onModalContentExtracted: () => void;
}

export const FormModal: FC<FormModalProps> = ({
  id,
  isOpen,
  setIsOpen,
  header,
  onModalContentExtracted,
}) => {
  const modal = useRef<Element | null>();

  const handleFormsSetupCompleted = useCallback(() => {
    const el = document.querySelector(`.FormModal__modalContent--${id}`);
    modal.current = el;
    onModalContentExtracted();
  }, [id, onModalContentExtracted]);

  useEffect(() => {
    document.addEventListener("DOMContentLoaded", () => {
      const form = window.$$epiforms(`form#${id}`);
      form.one("formsSetupCompleted", handleFormsSetupCompleted);
    });
  }, [handleFormsSetupCompleted, id]);

  const replaceContentAfterModalOpen = () => {
    const modalContent = document.querySelector("#FormModal__content");
    if (!modalContent || !modal.current) return;
    modalContent.replaceChildren(modal.current ?? "");
  };

  return (
    <Modal
      className="FormModal"
      dialogClassName="FormModal__dialog"
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      onAfterOpen={replaceContentAfterModalOpen}
    >
      <Modal.Body>
        <Modal.Header>
          <Modal.Title>{header}</Modal.Title>
        </Modal.Header>
        <div id="FormModal__content"></div>
      </Modal.Body>
    </Modal>
  );
};
