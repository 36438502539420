import React, { useState, useEffect, useCallback, useMemo } from "react";
import { OfficeLocationsList } from "./OfficeLocationsList";
import { ComponentProps } from "../ComponentProps.csharp";
import { OfficeLocationsViewModel } from "./OfficeLocationsViewModel.csharp";
import { OfficeLocationsFilterItemViewModel } from "./OfficeLocationsFilterItemViewModel.csharp";
import { OfficeLocationViewModel } from "./OfficeLocationViewModel.csharp";
import { SelectOption } from "../../react-components/Inputs/SelectOption";
import { OfficeLocationsFilters } from "./OfficeLocationsFilters";

export interface OfficeLocationListState {
    selectedCountry: string;
    selectedProductCategory: string;
    selectedBusinessArea: string;
    searchPhrase: string;
    selectedContactType: string
    batchNumber: number;
}

const QueryKeys = {
    SearchPhrase: "searchPhrase",
    ProductCategory: "productCategory",
    BusinessArea: "businessArea",
    ContactType: "contactType",
    Country: "country",
};

const setQueryValue = (key: string, value: string | null) => {
    const url = new URL(location.href);
    if (!value) {
        url.searchParams.delete(key);
    } else {
        url.searchParams.set(key, value);
    }
    history.replaceState(null, "", url);
};

const getQueryValue = (key: string): string | null => {
    const url = new URL(location.href);
    return url.searchParams.get(key);
};

export const OfficeLocationsContainer: React.FC<ComponentProps<OfficeLocationsViewModel>> = ({ model }) => {
    const [officeFilters, setOfficeFilters] = useState<OfficeLocationListState>({
        searchPhrase: "",
        selectedProductCategory: "",
        selectedBusinessArea: "",
        selectedContactType: "",
        selectedCountry: "",
        batchNumber: 1
    });

    useEffect(() => {
        if (window === undefined) {
            return;
        }

        setOfficeFilters({
            selectedProductCategory: getQueryValue(QueryKeys.ProductCategory) ?? "",
            selectedBusinessArea: getQueryValue(QueryKeys.BusinessArea) ?? "",
            selectedContactType: getQueryValue(QueryKeys.ContactType) ?? "",
            selectedCountry: getQueryValue(QueryKeys.Country) ?? "",
            searchPhrase: getQueryValue(QueryKeys.SearchPhrase) ?? "",
            batchNumber: 1
        });
    }, []);

    const matchesSearchPhrase = (searchPhrase: string, officeLocationViewModel: OfficeLocationViewModel): boolean => {
        if (searchPhrase.length === 0) {
            return true;
        }

        const searchContent = officeLocationViewModel.officeName.toLowerCase();
        const words = searchPhrase.match(/\S+/g) || []; //split into words by whitespaces

        const notFoundWords = words.filter((word) => searchContent.indexOf(word) < 0);
        return notFoundWords.length === 0;
    };

    const resetFilters = () => {
        setQueryValue(QueryKeys.SearchPhrase, null);
        setQueryValue(QueryKeys.Country, null);
        setQueryValue(QueryKeys.ProductCategory, null);
        setQueryValue(QueryKeys.BusinessArea, null);
        setQueryValue(QueryKeys.ContactType, null);

        setOfficeFilters({
            searchPhrase: "",
            selectedProductCategory: "",
            selectedBusinessArea: "",
            selectedContactType: "",
            selectedCountry: "",
            batchNumber: 1
        });
    };

    const matchesSelectedFilter = (officeLocationViewModel: OfficeLocationViewModel): boolean => {
        if (
          officeFilters.selectedContactType &&
          !officeLocationViewModel.relatedProductCategoriesPerType[parseInt(officeFilters.selectedContactType)]
        ) {
            return false;
        }

        if (
          officeFilters.selectedBusinessArea.length > 0 &&
          !officeLocationViewModel.businessAreas.includes(officeFilters.selectedBusinessArea)
        ) {
            return false;
        }

        if (
          officeFilters.selectedProductCategory &&
          !officeLocationViewModel.relatedProductCategories.map(x => x.id).includes(parseInt(officeFilters.selectedProductCategory))
        ) {
            return false;
        }

        if (
          officeFilters.selectedCountry &&
          officeLocationViewModel.address.country !== officeFilters.selectedCountry
        ) {
            return false;
        }


        const searchPhrase = officeFilters.searchPhrase.trim();
        if (searchPhrase.length > 0 && !matchesSearchPhrase(searchPhrase.toLowerCase(), officeLocationViewModel)) {
            return false;
        }

        return true;
    };

    const filteredOffices = useMemo(
      () => model.officeLocations.filter((x) => matchesSelectedFilter(x)),
      [officeFilters],
    );

    const onSearchPhraseChange = (value: string) => {
        setOfficeFilters((prev) => ({
            ...prev,
            searchPhrase: value,
        }));
        setQueryValue(QueryKeys.SearchPhrase, value);
    };

    const onSelectedCountry = useCallback((option: SelectOption) => {
        setOfficeFilters((prev) => ({ ...prev, batchNumber: 1, selectedCountry: option.value }));
        setQueryValue(QueryKeys.Country, option.value);
    }, []);

    const onSelectedProductCategory = useCallback((option: SelectOption) => {
        setOfficeFilters((prev) => ({ ...prev, batchNumber: 1, selectedProductCategory: option.value }));
        setQueryValue(QueryKeys.ProductCategory, option.value);
    }, []);

    const onSelectedContactType = useCallback((option: SelectOption) => {
        setOfficeFilters((prev) => ({ ...prev, batchNumber: 1, selectedContactType: option.value }));
        setQueryValue(QueryKeys.ContactType, option.value);
    }, []);

    const onSelectedBusinessArea = useCallback((option: SelectOption) => {
        setOfficeFilters((prev) => ({ ...prev, selectedBusinessArea: option.value }));
        setQueryValue(QueryKeys.ContactType, option.value);
    }, []);

    const onShowMore = useCallback(() => {
        setOfficeFilters((prev) => ({ ...prev, batchNumber: prev.batchNumber + 1 }));
    }, []);
    const generateOptions = (items: OfficeLocationsFilterItemViewModel[]): SelectOption[] =>
      items.map((item) => ({ value: item.value, label: item.text }));


    const numberOfVisibleOfficeLocations = officeFilters.batchNumber * 10;
    const officeLocationsToRender = filteredOffices.slice(0, numberOfVisibleOfficeLocations);
    const showLoadMoreButton = numberOfVisibleOfficeLocations < filteredOffices.length;

    return (
      <div className="OfficeLocations">
          <OfficeLocationsFilters
            countryOptions={generateOptions(model.countryFilter.items)}
            contactTypesOptions={generateOptions(model.contactTypeFilter.items)}
            businessAreaOptions={generateOptions(model.businessAreaFilter.items)}
            productCategoryOptions={generateOptions(model.productCategoryFilter.items)}
            onSearchPhraseChange={onSearchPhraseChange}
            onSelectedCountry={onSelectedCountry}
            onSelectedContactType={onSelectedContactType}
            onSelectedBusinessArea={onSelectedBusinessArea}
            onSelectedProductCategory={onSelectedProductCategory}
            officeLocationListFilters={officeFilters}
            resetFilters={resetFilters}
          />

          <OfficeLocationsList
            translations={model.listTranslations}
            officeLocations={officeLocationsToRender}
            allOfficesLenght={filteredOffices.length}
            loadMoreButtonVisible={showLoadMoreButton}
            onShowMore={onShowMore}
          />
      </div>
    );
};