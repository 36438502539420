import * as React from "react";
import {OfficeLocationViewModel} from "./OfficeLocationViewModel.csharp";
import {OfficeLocationsListTranslations} from "./OfficeLocationsListTranslations.csharp";
import { MapPin } from "../../react-components/Icons/MapPin";
import { ArrowRight } from "../../react-components/Icons/ArrowRight";

export interface OfficeLocationsListProps {
    officeLocations: OfficeLocationViewModel[];
    allOfficesLenght: number;
    loadMoreButtonVisible: boolean;
    onShowMore: () => void;
    translations: OfficeLocationsListTranslations;
}

export const OfficeLocationsList = (props: OfficeLocationsListProps) => {
  const ItemTitleTag = "span";
  return (
    <>
      <ul className="OfficeLocationsItemList" aria-live="polite" aria-relevant="additions">
        {props.officeLocations.map((officeLocation, idx) => {
          const email = officeLocation.emailAddress && officeLocation.emailAddress.split("@");
          return (
            <li key={idx}>
              <a href={officeLocation.readMoreUrl} className="OfficeLocationItem">
                <div className="OfficeLocationItem__itemTextsWrapper">
                  <ItemTitleTag className="OfficeLocationItem__title">{officeLocation.officeName}</ItemTitleTag>
                  {officeLocation.address && (
                    <address className="LinkArrowBlockList__itemLocationWrapper">
                      <span className="visuallyHidden">Location:</span>
                      <MapPin aria-hidden />
                      <div
                        className="LinkArrowBlockList__itemLocationText">{officeLocation.address.country}, {officeLocation.address.city}</div>
                    </address>
                  )}

                  <span className="OfficeLocationItem__contact">  
                    {officeLocation.phoneNumber &&
                      <span>{props.translations.phoneText} {officeLocation.phoneNumber}</span>
                    }
                    {email && email.length === 2 &&
                      <a href={`mailto:${officeLocation.emailAddress}`}>{email[0]
                      }
                        <wbr />
                        @{email[1]
                        }</a>
                    }
                  </span>
                </div>

                <div className="LinkArrowBlockList__itemArrowWrapper">
                    <span className="LinkArrowBlockList__itemReadMore" aria-hidden>
                      read more
                    </span>
                  <ArrowRight aria-hidden />
                </div>
              </a>
            </li>
          );
        })}
      </ul>
      <div className="OfficeLocations__showMoreContainer">
        <span>
          Showed {props.officeLocations.length} of {props.allOfficesLenght} Kongsberg locations
        </span>
        {props.loadMoreButtonVisible && <div className="OfficeLocation__buttons">
          <button className="ButtonFill--blue" onClick={props.onShowMore}>{props.translations.showMoreText}</button>
        </div>}
      </div>
    </>
  );
};