import React from "react";
import { InteractiveHeroHotSpotViewModel } from "./InteractiveHeroHotSpotViewModel.csharp";
import classNames from "classnames";

export const HotSpot: React.FC<InteractiveHeroHotSpotViewModel> = ({
  targetUrl,
  text,
  iconSvg,
  outlineColor,
  x,
  y,
}) => {
  return (
    <a
      className={classNames(
        "InteractiveHeroBlock__HotSpotLink",
        `InteractiveHeroBlock__HotSpotLink--${outlineColor}`,
      )}
      href={targetUrl}
      style={{
        left: `${x}%`,
        top: `${y}%`,
      }}
    >
      <span className="InteractiveHeroBlock__Text">{text}</span>
      <div className="InteractiveHeroBlock__Icon">
        <div className="InteractiveHeroBlock__Inner">
          <span dangerouslySetInnerHTML={{ __html: iconSvg }} />
        </div>
      </div>
    </a>
  );
};
