import React, { FC, useMemo } from "react";
import { ProductNavbarViewModel } from "./ProductNavbarViewModel.csharp";
import { ComponentProps } from "../../../Partials/ComponentProps.csharp";
import { SectionsNavbar } from "../../../react-components/SectionsNavbar/SectionsNavbar";
import { INavbarItemViewModel } from "../../../Common/Navbar/INavbarItemViewModel.csharp";
import { useIsBelowScreenSize } from "../../../react-components/useIsBelowScreenSize";

export const ProductNavbar: FC<ComponentProps<ProductNavbarViewModel>> = ({
  model: { sections, menuSliderTranslations },
}) => {
  const isMobile = useIsBelowScreenSize("tablet");

  const availableSections = useMemo(() => {
    const allSections = Object.values(sections) as INavbarItemViewModel[];
    const existingSections = allSections
      .filter((section) => section.exists)
      .filter((section) => (!isMobile ? section.anchor !== "introduction" : true));

    return existingSections;
  }, [isMobile, sections]);

  return (
    <SectionsNavbar
      sections={availableSections}
      translations={menuSliderTranslations}
      itemsColor="teal"
    />
  );
};
