import React, { FC } from "react";
import { CountProps } from "../../react-components/Atoms/Count/Count";
import { RadioSelectOption } from "../../react-components/Discovery/Inputs/RadioSelect/RadioSelect.types";
import { RadioSelect } from "../../react-components/Discovery/Inputs/RadioSelect/RadioSelect";
import { OnChangeValue } from "react-select";
import { ButtonGhost } from "../../react-components/Buttons/ButtonGhost";
import { Reset } from "../../react-components/Discovery/Icons/Reset";
import { FilterInput } from "../../react-components/Inputs/FilterInput/FilterInput";

interface VacancyListFiltersModalProps {
  positionOptions: RadioSelectOption[];
  departmentOptions: RadioSelectOption[];
  regionOptions: RadioSelectOption[];
  onSearchPhraseChange: (value: string) => void;
  onSelectedRegion: (value: RadioSelectOption) => void;
  onSelectedPositionType: (value: RadioSelectOption) => void;
  onSelectedDepartment: (value: RadioSelectOption) => void;
  searchPhrase: string;
  department?: RadioSelectOption;
  position?: RadioSelectOption;
  region?: RadioSelectOption;
  filtersCount: CountProps;
  resetFilters: () => void;
  showResetFiltersButton: boolean;
}

export const VacancyListFiltersMobile: FC<VacancyListFiltersModalProps> = ({
  positionOptions,
  departmentOptions,
  regionOptions,
  onSearchPhraseChange,
  onSelectedDepartment,
  onSelectedPositionType,
  onSelectedRegion,
  searchPhrase,
  department,
  position,
  region,
  resetFilters,
  showResetFiltersButton,
}) => {
  const onRegionChange = (newValue: OnChangeValue<RadioSelectOption, false>) => {
    onSelectedRegion(newValue!);
  };

  const onDepartmentChange = (newValue: OnChangeValue<RadioSelectOption, false>) => {
    onSelectedDepartment(newValue!);
  };

  const onPositionChange = (newValue: OnChangeValue<RadioSelectOption, false>) => {
    onSelectedPositionType(newValue!);
  };

  return (
    <div className="FiltersListMobile">
      <ul className="FiltersList">
        <li className="FiltersList__item">
          <FilterInput
            label="Search"
            value={searchPhrase}
            onValueChanged={onSearchPhraseChange}
          />
        </li>
        <li className="FiltersList__item">
          <RadioSelect
            options={regionOptions}
            hiddenLabel="location"
            title={region ? region.label : "All Locations"}
            currentValue={region}
            placeholder="All Locations"
            onChange={onRegionChange}
            name="location"
          />
        </li>
        <li className="FiltersList__item">
          <RadioSelect
            options={positionOptions}
            hiddenLabel="position"
            title={position ? position.label : "All Positions"}
            currentValue={position}
            placeholder="All Positions"
            onChange={onPositionChange}
            name="position"
          />
        </li>

        <li className="FiltersList__item">
          <RadioSelect
            options={departmentOptions}
            hiddenLabel="department"
            title={department ? department.label : "All Business Areas"}
            currentValue={department}
            placeholder="All Business Areas"
            onChange={onDepartmentChange}
            name="department"
          />
        </li>
        
        {showResetFiltersButton && (
          <li className="FiltersList__item">
            <ButtonGhost
              iconPosition="right"
              icon={Reset}
              mode="light"
              color="blue"
              onClick={resetFilters}
              className="FiltersList__resetBtn"
            >
              Reset filters
            </ButtonGhost>
          </li>
        )}
      </ul>
    </div>
  );
};