import { CSSProperties, useEffect, useRef, useState } from "react";

import { usePageLoaded } from "../hooks/usePageLoaded";
import { INavbarItemViewModel } from "../../Common/Navbar/INavbarItemViewModel.csharp";
import { useReactWrapper } from "../hooks/useReactWrapper";
import { useGlobalStore } from "../../stores/globalStore";
import { useStyles } from "../hooks/useStyles";
import { isBrowser } from "../../environmentHelpers";

interface SectionsNavbarBodyCSSProperties extends CSSProperties {
  "--sections-navbar-scroll-margin": string;
}

export interface SectionsNavbarWrapperCSSProperties extends CSSProperties {
  "--sections-navbar-offset": string;
}

export const useSections = (
  sections: INavbarItemViewModel[],
  changeSlide: (slideIndex: number) => void,
) => {
  const [activeSections, setActiveSections] = useState<string[]>([]);
  const [activeAnchor, setActiveAnchor] = useState<string | null>(null);
  const observerRef = useRef<IntersectionObserver>();
  const isPageLoaded = usePageLoaded();

  const wrapper = useReactWrapper("Navbar");
  const header = useGlobalStore((state) => state.layout.header);
  const productBanner = useGlobalStore((state) => state.product.banner);
  const sectionsNavbar = useGlobalStore((state) => state.product.navbar);

  useStyles<SectionsNavbarWrapperCSSProperties>(
    wrapper,
    {
      "--sections-navbar-offset": `${header.height + productBanner.height}px`,
    },
    [productBanner.height, header.height],
  );

  useStyles<SectionsNavbarBodyCSSProperties>(
    isBrowser ? document.body : null,
    {
      "--sections-navbar-scroll-margin": `${
        header.height + productBanner.height + sectionsNavbar.height
      }px`,
    },
    [productBanner.height, header.height, sectionsNavbar.height],
  );

  useEffect(() => {
    if (isPageLoaded) {
      observerRef.current = new IntersectionObserver(
        (entries) => {
          const checkedSections = [] as string[];

          entries.forEach((entry) => {
            const section = sections.find((section) => section.anchor === entry.target.id);

            if (section && entry.isIntersecting) {
              checkedSections.push(section.anchor);
            }
          });

          setActiveSections(checkedSections);

          const firstCheckedSectionAnchor = checkedSections.length > 0 && checkedSections[0];

          if (firstCheckedSectionAnchor) {
            setActiveAnchor(firstCheckedSectionAnchor);
          }
        },
        {
          rootMargin: "-50% 0px",
        },
      );

      sections.forEach((section) => {
        const element = document.getElementById(section.anchor);

        if (observerRef.current && element) {
          observerRef.current.observe(element);
        }
      });

      return () => observerRef.current?.disconnect();
    }
  }, [sections, isPageLoaded]);

  useEffect(() => {
    if (activeAnchor) {
      const sectionIndex = sections.findIndex((section) => section.anchor === activeAnchor);

      if (sectionIndex !== -1) {
        changeSlide(sectionIndex);
      }
    }
  }, [activeAnchor, changeSlide, sections]);

  return activeSections;
};
