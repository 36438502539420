import React, { memo } from "react";
import { ButtonGhost } from "../../../react-components/Buttons/ButtonGhost";
import { Plus } from "../../../react-components/Icons/Plus";

export interface ImageWithFactsViewerScrollListItemProps {
  letter: string;
  title: string;
  description: string;
  buttonLabel?: string;
  onHover?: () => void;
  onButtonClick?: () => void;
}

export const ImageWithFactsViewerScrollListItem = memo<ImageWithFactsViewerScrollListItemProps>(
  function ImageWithFactsViewerSingleFactExtended({
    letter,
    title,
    description,
    buttonLabel,
    onHover,
    onButtonClick,
  }) {
    return (
      <article className="ImageWithFactsViewer__singleFact" onMouseEnter={onHover}>
        <div className="ImageWithFactsViewer__singleFactHeader">
          <span className="ImageWithFactsViewer__singleFactLetter">{letter}</span>
          <h3 className="ImageWithFactsViewer__singleFactTitle">{title}</h3>
        </div>
        <div className="ImageWithFactsViewer__singleFactContent">
          {description.length > 0 && (
            <div className="ImageWithFactsViewer__singleFactDescription">
              <p>{description}</p>
            </div>
          )}
          {buttonLabel && (
            <ButtonGhost
              color="blue"
              iconPosition="left"
              icon={Plus}
              size="small"
              onClick={onButtonClick}
            >
              {buttonLabel}
            </ButtonGhost>
          )}
        </div>
      </article>
    );
  },
);
