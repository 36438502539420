/* eslint-disable react/no-unknown-property */
import React, { FC } from "react";
import { CallToActionButtonProps } from "./CallToActionButtonProps.csharp";
import { ComponentProps } from "../../ComponentProps.csharp";
import classNames from "classnames";
import { CallToActionModalButton } from "./CallToActionModalButton";
import { CommonBlockColorExtended } from "../../../../Models/Blocks/CommonBlockColorExtended.csharp";

export const CallToActionButton: FC<ComponentProps<CallToActionButtonProps>> = ({
  model: { id, text, hasModal, modalHeader, link, isSecondary, color },
}) => {
  const isColored = !!color && color !== CommonBlockColorExtended.Default;
  const className = classNames([
    "CallToActionButton",
    {
      [`CallToActionButton--colored CallToActionButton--${color}`]: isColored,
      ["CallToActionButton--secondary"]: isSecondary,
      ["CallToActionButton--primary"]: !isSecondary,
    },
  ]);

  if (hasModal) {
    return (
      <CallToActionModalButton id={id} className={className} header={modalHeader} text={text} />
    );
  }

  if (link && link.href) {
    return (
      <a className={className} href={link.href} target={link.target} title={link.title}>
        {text}
      </a>
    );
  }

  return <></>;
};
