import classNames from "classnames";
import React from "react";

interface ImageCaptionProps {
  className?: string;
  captionHtml?: string;
}

export const ImageCaption = ({ className, captionHtml }: ImageCaptionProps) => {
  const additionalClassName = className && `${className}__caption`;

  return captionHtml ? (
    <figcaption
      className={classNames("ImageWithCaptionComponent__caption", additionalClassName)}
      dangerouslySetInnerHTML={{ __html: captionHtml }}
    />
  ) : null;
};
