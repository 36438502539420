import { FC, useMemo, useRef, useState } from "react";
import { ButtonOutlined } from "../../react-components/Buttons/ButtonOutlined";
import Share from "../../../wwwroot/Static/Images/Icons/Share.svg";
import * as React from "react";
import { VacancyListState } from "./VacancyList";
import { useIsBelowScreenSize } from "../../react-components/useIsBelowScreenSize";
import { VacancyListFiltersMobile } from "./VacancyListFiltersMobile";
import { Tooltip } from "../../react-components/Discovery/Tooltip/Tooltip";
import { TooltipTrigger } from "../../react-components/Discovery/Tooltip/TooltipTrigger";
import { TooltipContent } from "../../react-components/Discovery/Tooltip/TooltipContent";
import { ButtonGhost } from "../../react-components/Buttons/ButtonGhost";
import { SelectOption } from "../../react-components/Inputs/SelectOption";
import { Reset } from "../../react-components/Discovery/Icons/Reset";
import { useClipboard } from "../../react-components/hooks/useClipboard";
import { FilterSelect } from "../../react-components/Inputs/FilterSelect/FilterSelect";
import { FilterInput } from "../../react-components/Inputs/FilterInput/FilterInput";

interface VacancyListFiltersProps {
  positionOptions: SelectOption[];
  departmentOptions: SelectOption[];
  regionOptions: SelectOption[];
  onSearchPhraseChange: (value: string) => void;
  onSelectedRegion: (value: SelectOption) => void;
  onSelectedPositionType: (value: SelectOption) => void;
  onSelectedDepartment: (value: SelectOption) => void;
  vacancyFilters: VacancyListState;
  vacanciesCount: number;
  resetFilters: () => void;
}

export const VacancyListFilters: FC<VacancyListFiltersProps> = ({
  positionOptions,
  departmentOptions,
  regionOptions,
  onSearchPhraseChange,
  onSelectedDepartment,
  onSelectedPositionType,
  onSelectedRegion,
  vacancyFilters,
  vacanciesCount,
  resetFilters,
}) => {
  const [isShareTooltipVisible, setIsShareTooltipVisible] = useState<boolean>(false);
  const isMobile = useIsBelowScreenSize("tablet");
  const { copy } = useClipboard();
  const topDivRef = useRef<HTMLDivElement>(null);

  const scrollToTopDiv = () => {
    if (topDivRef.current) {
      topDivRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  
  const copyUrlToClipboard = () => {
    copy(location.href);
    setIsShareTooltipVisible(true);

    setTimeout(() => {
      setIsShareTooltipVisible(false);
    }, 3000);
  };

  const currentRegion = useMemo(() => {
    if (vacancyFilters.selectedRegionId.length === 0) {
      return undefined;
    }

    return regionOptions.find((option) => option.value === vacancyFilters.selectedRegionId);
  }, [vacancyFilters.selectedRegionId]);

  const currentPosition = useMemo(() => {
    if (vacancyFilters.selectedPositionTypeId.length === 0) {
      return undefined;
    }

    return positionOptions.find((option) => option.value === vacancyFilters.selectedPositionTypeId);
  }, [vacancyFilters.selectedPositionTypeId]);

  const currentDepartment = useMemo(() => {
    if (vacancyFilters.selectedDepartmentId.length === 0) {
      return undefined;
    }

    return departmentOptions.find((option) => option.value === vacancyFilters.selectedDepartmentId);
  }, [vacancyFilters.selectedDepartmentId]);

  const showResetFiltersButton =
    currentRegion || currentPosition || currentDepartment || vacancyFilters.searchPhrase?.length;

  const withScrollToTop = <T, >(handler: (value: T) => void): ((value: T) => void) => {
    return (value: T) => {
      scrollToTopDiv();
      handler(value);
    };
  };

  return (
    <>
      <div ref={topDivRef} className="TopDivAboveFilters" />
      <div className="Filters Filters--sand">
        {!isMobile ? (
          <ul className="FiltersList">
            <li className="FiltersList__item">
              <FilterInput
                label="Search"
                value={vacancyFilters.searchPhrase}
                onValueChanged={withScrollToTop(onSearchPhraseChange)}
              />
            </li>
            <li className="FiltersList__item">
              <FilterSelect
                label={currentRegion ? currentRegion.label : "All Locations"}
                name="location"
                options={regionOptions}
                onChange={withScrollToTop(onSelectedRegion)}
                placeholder="All locations"
                placeholderAsFirstOption
                currentValue={currentRegion}
                heading="Location"
              />
            </li>
            <li className="FiltersList__item">
              <FilterSelect
                label={currentPosition ? currentPosition.label : "All Positions"}
                name="position"
                options={positionOptions}
                onChange={withScrollToTop(onSelectedPositionType)}
                placeholder="All positions"
                placeholderAsFirstOption
                currentValue={currentPosition}
                heading="Position"
              />
            </li>
            <li className="FiltersList__item">
              <FilterSelect
                label={currentDepartment ? currentDepartment.label : "All Business Areas"}
                name="business-area"
                options={departmentOptions}
                onChange={withScrollToTop(onSelectedDepartment)}
                placeholder="All Business Areas"
                placeholderAsFirstOption
                currentValue={currentDepartment}
                heading="Business Area"
              />
            </li>
            <li className="FiltersList__item">
              <Tooltip trigger="click">
                <TooltipTrigger
                  className="FiltersList__tooltipTrigger"
                  onClick={copyUrlToClipboard}
                >
                  <ButtonOutlined
                    color="blue"
                    icon={Share}
                    iconPosition="right"
                    title="Share"
                    className="FiltersList__item--share"
                    as="div"
                  />
                </TooltipTrigger>
                {isShareTooltipVisible && (
                  <TooltipContent maxWidth={164} className="TechnicalSpecifications__tooltipContent">
                    Copied
                  </TooltipContent>
                )}
              </Tooltip>
            </li>
            {!!showResetFiltersButton && (
              <li className="FiltersList__item">
                <ButtonGhost
                  iconPosition="right"
                  icon={Reset}
                  mode="light"
                  color="blue"
                  onClick={withScrollToTop(resetFilters)}
                  className="FiltersList__resetBtn"
                >
                  Reset filters
                </ButtonGhost>
              </li>
            )}
          </ul>
        ) : (
          <>
            <VacancyListFiltersMobile
              positionOptions={positionOptions}
              regionOptions={regionOptions}
              departmentOptions={departmentOptions}
              onSearchPhraseChange={onSearchPhraseChange}
              onSelectedRegion={onSelectedRegion}
              onSelectedPositionType={onSelectedPositionType}
              onSelectedDepartment={onSelectedDepartment}
              region={currentRegion}
              position={currentPosition}
              department={currentDepartment}
              searchPhrase={vacancyFilters.searchPhrase}
              filtersCount={{ label: "results", count: vacanciesCount, variant: "bold" }}
              resetFilters={resetFilters}
              showResetFiltersButton={!!showResetFiltersButton}
            />
            <div className="FiltersList__tooltipWrapper">
              <Tooltip trigger="click">
                <TooltipTrigger className="TechnicalSpecifications__tooltipTrigger">
                  <ButtonGhost
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={copyUrlToClipboard}
                    color="blue"
                    icon={Share}
                    iconPosition="right"
                    title="Share"
                    className="FiltersList__item--share"
                  />
                </TooltipTrigger>
                {isShareTooltipVisible && (
                  <TooltipContent maxWidth={164} className="TechnicalSpecifications__tooltipContent">
                    Copied
                  </TooltipContent>
                )}
              </Tooltip>
            </div>
          </>
        )}
      </div>
    </>
  );
};