import React, { FC, useState } from "react";
import classNames from "classnames";
import { ImageWithFactsViewerModal } from "./Modal/ImageWithFactsViewerModal";
import { ImageWithFactsViewerScrollListItem } from "./ImageWithFactsViewerScrollListItem";
import { useFacts } from "../useFacts";
import { ImagesAndFactsScrollListViewModel } from "./ImagesAndFactsScrollListViewModel.csharp";
import { ComponentProps } from "../../ComponentProps.csharp";

export const ImageWithFactsScrollList: FC<ComponentProps<ImagesAndFactsScrollListViewModel>> = ({
  model: { facts, mainImage, modalPictureProfile },
}) => {
  const [factsList, selectedFact, setSelectedFact, getActiveImage] = useFacts(facts, mainImage);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className={classNames("ImageWithFactsViewer", "ImageWithFactsViewer--scrollList")}>
      <div className="ImageWithFactsViewer__container">
        <ul
          className="ImageWithFactsViewer__list"
          onMouseLeave={() => {
            if (!isModalOpen) setSelectedFact(null);
          }}
        >
          {factsList.map((fact) => (
            <React.Fragment key={fact.id}>
              <li className="ImageWithFactsViewer__listItem">
                <ImageWithFactsViewerScrollListItem
                  letter={fact.letter}
                  title={fact.title}
                  description={fact.introduction}
                  buttonLabel={fact.descriptionParagraphs.length !== 0 ? "Learn more" : undefined}
                  onHover={() => setSelectedFact(fact)}
                  onButtonClick={() => {
                    setSelectedFact(fact);
                    setIsModalOpen(true);
                  }}
                />
              </li>
            </React.Fragment>
          ))}
        </ul>
      </div>

      <div className="ImageWithFactsViewer__imageContainer">{getActiveImage()}</div>
      <ImageWithFactsViewerModal
        isOpen={isModalOpen && !!selectedFact}
        selectedFact={selectedFact}
        onClose={() => {
          setSelectedFact(null);
          setIsModalOpen(false);
        }}
        modalPictureProfile={modalPictureProfile}
      />
    </div>
  );
};
