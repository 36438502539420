import React, { FC } from "react";
import { ImageViewerItemViewModel } from "../../Partials/ImageViewer/ImageViewerItemViewModel.csharp";
import { PictureProfileDto } from "../../Images/Resizing/PictureProfileDto.csharp";
import { ImageCaption } from "./ImageCaption";
import { ResponsivePicture } from "../responsivePicture/responsivePicture";
import classNames from "classnames";

export interface ImageWithCaptionProps {
  className?: string;
  image: Omit<Optional<ImageViewerItemViewModel, "captionHtml">, "captionBackground">;
  profile: PictureProfileDto;
  hideCaption?: boolean;
  darkMode?: boolean;
}

export const ImageWithCaption: FC<ImageWithCaptionProps> = ({
  className,
  image,
  profile,
  hideCaption = false,
  darkMode = false,
}) => {
  const showCaption = !hideCaption && !!image.captionHtml;

  const additionalClassName = className && `${className}__figure`;

  return (
    <figure
      className={classNames("ImageWithCaptionComponent__figure", additionalClassName, {
        "ImageWithCaptionComponent__figure--dark": darkMode,
      })}
    >
      <ResponsivePicture model={image.picture} profile={profile} />
      {showCaption && <ImageCaption className={className} captionHtml={image.captionHtml} />}
    </figure>
  );
};
