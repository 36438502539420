import React, { memo } from "react";
import ReactModal from "react-modal";
import { Plus } from "../../../../react-components/Icons/Plus";
import { ImageAndFactWithIdentity } from "../../useFacts";
import { ImageAndFactScrollListViewModel } from "../ImageAndFactScrollListViewModel.csharp";
import classNames from "classnames";
import { PictureProfileDto } from "../../../../Images/Resizing/PictureProfileDto.csharp";
import { ImageWithCaption } from "../../../../react-components/Images/ImageWithCaption";

interface ImageWithFactsViewerModalProps {
  isOpen: boolean;
  selectedFact: ImageAndFactWithIdentity<ImageAndFactScrollListViewModel> | null;
  modalPictureProfile: PictureProfileDto;
  onClose?: () => void;
}

export const ImageWithFactsViewerModal = memo<ImageWithFactsViewerModalProps>(
  function ImageWithFactsViewerModal({ isOpen, selectedFact, modalPictureProfile, onClose }) {
    if (!isOpen || !selectedFact) return null;

    const { letter, title, descriptionParagraphs, modalImage } = selectedFact;

    return (
      <ReactModal
        isOpen={isOpen}
        overlayClassName="ImageWithFactsViewerModal"
        className="ImageWithFactsViewerModal__container"
        ariaHideApp={false}
        onRequestClose={onClose}
        shouldCloseOnOverlayClick
      >
        <div className="ImageWithFactsViewerModal__header">
          <div className="ImageWithFactsViewerModal__headerHeading">
            <span className="ImageWithFactsViewerModal__headerLetter">{letter}</span>
            <h2 className="ImageWithFactsViewerModal__headerTitle">{title}</h2>
          </div>
          <button
            className="ImageWithFactsViewerModal__headerCloseButton"
            aria-label="Close"
            onClick={onClose}
          >
            <Plus />
          </button>
        </div>

        <div
          className={classNames("ImageWithFactsViewerModal__content", {
            "ImageWithFactsViewerModal__content--withImage": !!modalImage,
          })}
        >
          <div className="ImageWithFactsViewerModal__text">
            {descriptionParagraphs.map((p, i) => (
              <p key={i}>{p}</p>
            ))}
          </div>
          {!!modalImage && (
            <ImageWithCaption
              className="ImageWithFactsViewerModal"
              image={modalImage}
              profile={modalPictureProfile}
            />
          )}
        </div>
      </ReactModal>
    );
  },
);
