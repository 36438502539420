import React, { FC } from "react";
import { RadioSelectOption } from "../../react-components/Discovery/Inputs/RadioSelect/RadioSelect.types";
import { RadioSelect } from "../../react-components/Discovery/Inputs/RadioSelect/RadioSelect";
import { OnChangeValue } from "react-select";
import { ButtonGhost } from "../../react-components/Buttons/ButtonGhost";
import { Reset } from "../../react-components/Discovery/Icons/Reset";
import { FilterInput } from "../../react-components/Inputs/FilterInput/FilterInput";

interface OfficeListFiltersModalProps {
  countryOptions: RadioSelectOption[];
  contactTypesOptions: RadioSelectOption[];
  businessAreaOptions: RadioSelectOption[];
  productCategoryOptions: RadioSelectOption[];
  onSearchPhraseChange: (value: string) => void;
  onSelectedCountry: (value: RadioSelectOption) => void;
  onSelectedContactType: (value: RadioSelectOption) => void;
  onSelectedBusinessArea: (value: RadioSelectOption) => void;
  onSelectedProductCategory: (value: RadioSelectOption) => void;
  searchPhrase: string;
  country?: RadioSelectOption;
  contactType?: RadioSelectOption;
  businessArea?: RadioSelectOption;
  productCategory?: RadioSelectOption;
  resetFilters: () => void;
  showResetFiltersButton: boolean;
}

export const OfficeListFiltersMobile: FC<OfficeListFiltersModalProps> = ({
   countryOptions,
   contactTypesOptions,
   businessAreaOptions,
   productCategoryOptions,
   onSearchPhraseChange,
   onSelectedContactType,
   onSelectedProductCategory,
   onSelectedBusinessArea,
   onSelectedCountry,
   searchPhrase,
   country,
   contactType,
   businessArea,
   productCategory,
   showResetFiltersButton,
   resetFilters, 
   }) => {
  const onCountryChange = (newValue: OnChangeValue<RadioSelectOption, false>) => {
    onSelectedCountry(newValue!);
  };

  const onContactTypeChange = (newValue: OnChangeValue<RadioSelectOption, false>) => {
    onSelectedContactType(newValue!);
  };

  const onBusinessAreaChange = (newValue: OnChangeValue<RadioSelectOption, false>) => {
    onSelectedBusinessArea(newValue!);
  };

  const onProductCategoryChange = (newValue: OnChangeValue<RadioSelectOption, false>) => {
    onSelectedProductCategory(newValue!);
  };

  return (
    <div className="FiltersListMobile">
      <ul className="FiltersList">
        <li className="FiltersList__item">
          <FilterInput
            label="Search"
            value={searchPhrase}
            onValueChanged={onSearchPhraseChange}
          />
        </li>
        <li className="FiltersList__item">
          <RadioSelect
            options={contactTypesOptions}
            hiddenLabel="position"
            title={contactType ? contactType.label : "Contact"}
            currentValue={contactType}
            placeholder="All Contacts"
            onChange={onContactTypeChange}
            name="contactType"
          />
        </li>
        <li className="FiltersList__item">
          <RadioSelect
            options={countryOptions}
            hiddenLabel="location"
            title={country ? country.label : "Locations"}
            currentValue={country}
            placeholder="All Locations"
            onChange={onCountryChange}
            name="location"
          />
        </li>
        {productCategoryOptions.length > 0 && (
          <li className="FiltersList__item">
          <RadioSelect
            options={productCategoryOptions}
            hiddenLabel="All products"
            title={productCategory ? productCategory.label : "All products"}
            currentValue={productCategory}
            placeholder="All products"
            onChange={onProductCategoryChange}
            name="productCategory"
          />
        </li>
        )}
        {businessAreaOptions.length > 0 && (<li className="FiltersList__item">
            <RadioSelect
              options={businessAreaOptions}
              hiddenLabel="department"
              title={businessArea ? businessArea.label : "All Business Areas"}
              currentValue={businessArea}
              placeholder="All Business Areas"
              onChange={onBusinessAreaChange}
              name="department"
          />
        </li>
        )}
        {showResetFiltersButton && (
          <li className="FiltersList__item">
            <ButtonGhost
              iconPosition="right"
              icon={Reset}
              mode="light"
              color="blue"
              onClick={resetFilters}
              className="FiltersList__resetBtn"
            >
              Reset filters
            </ButtonGhost>
          </li>
        )}
      </ul>
    </div>
  );
};