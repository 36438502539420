import React, { FC } from "react";
import { ComponentProps } from "../../Partials/ComponentProps.csharp";
import { NavbarProps } from "./NavbarProps.csharp";
import { SectionsNavbar } from "../../react-components/SectionsNavbar/SectionsNavbar";

export const Navbar: FC<ComponentProps<NavbarProps>> = ({ model }) => {
  const { sections, translations } = model;

  return <SectionsNavbar sections={sections} translations={translations} itemsColor="base" />;
};
