import React, { FC } from "react";
import classNames from "classnames";
import { ImageWithFactsViewerSingleFactDefault } from "./ImageWithFactsViewerSingleFactDefault";
import { useFacts } from "./useFacts";
import { ComponentProps } from "../ComponentProps.csharp";
import { ImagesAndFactsAccordionListViewModel } from "./ImagesAndFactsAccordionListViewModel.csharp";

export const ImageWithFactsAccordionList: FC<
  ComponentProps<ImagesAndFactsAccordionListViewModel>
> = ({ model: { facts, mainImage, mainLabel } }) => {
  const [factsList, selectedFact, setSelectedFact, getActiveImage] = useFacts(facts, mainImage);

  return (
    <div className={classNames("ImageWithFactsViewer")}>
      <div className="ImageWithFactsViewer__container">
        <ul className="ImageWithFactsViewer__list">
          {mainLabel && (
            <li className="ImageWithFactsViewer__listItem ImageWithFactsViewer__listItem--mainItem">
              <button
                className={classNames(
                  "ImageWithFactsViewer__button",
                  "ImageWithFactsViewer__button--mainView",
                  {
                    "ImageWithFactsViewer__button--active": selectedFact === null,
                  },
                )}
                onClick={() => setSelectedFact(null)}
              >
                {mainLabel}
              </button>
            </li>
          )}

          {factsList.map((fact) => (
            <li key={fact.id} className="ImageWithFactsViewer__listItem">
              <ImageWithFactsViewerSingleFactDefault
                id={fact.id}
                title={fact.title}
                description={fact.descriptionParagraphs[0]}
                isSelected={fact.id === selectedFact?.id}
                onSelect={() => setSelectedFact(fact)}
                onDeselect={() => setSelectedFact(null)}
              />
            </li>
          ))}
        </ul>
      </div>

      <div className="ImageWithFactsViewer__imageContainer">{getActiveImage()}</div>
    </div>
  );
};
